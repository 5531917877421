export const VN_INDEX_VIP_DOMAIN = "vnindexvipplus.com";
export const LOTTO_SUPPER_RICh_DOMAIN = "lottosuperrichplus.com";
export const SGX_VIP_DOMAIN = "sgxvipplus.com";
export const LSX_VIP_DOMAIN = "lsxvipplus.com";
export const HENG_SENG_VIDOMAIN = "hangsengvipplus.com";
export const SHEN_ZHEN_INDEX_DOMAIN = "shenzhenindex-plus.com";
export const NIKKEI_VIP_STOCKDOMAIN = "nikkeivipstockplus.com";
export const KTOP_VIP_INDEX_DOMAIN = "ktopvipindexplus.com";
export const TSEC_VIP_INDEX_DOMAIN = "tsecvipindexplus.com";
export const DOW_JONES_POWER_BALL_DOMAIN = "dowjonespowerball-plus.com";
export const INDIA_VIP_DOMAIN = "";
export const INDIA_VIP_PLUS_DOMAIN = "";

export const JACKPOT_VN_INDEX_VIP_DOMAIN = "jackpot.vnindexvip.com";
export const JACKPOT_LOTTO_SUPPER_RICh_DOMAIN = "jackpot.lottosuperrich.com";
export const JACKPOT_SGX_VIP_DOMAIN = "sgxvipplus.com"; // ยังไม่มี
export const JACKPOT_LSX_VIP_DOMAIN = "jackpot.lsxvip.com";
export const JACKPOT_HENG_SENG_VIDOMAIN = "jackpot.hangsengvip.com";
export const JACKPOT_SHEN_ZHEN_INDEX_DOMAIN = "jackpot.shenzhenindex.com";
export const JACKPOT_NIKKEI_VIP_STOCKDOMAIN = "jackpot.nikkeivipstock.com";
export const JACKPOT_KTOP_VIP_INDEX_DOMAIN = "jackpot.ktopvipindex.com";
export const JACKPOT_TSEC_VIP_INDEX_DOMAIN = "jackpot.tsecvipindex.com";
export const JACKPOT_DOW_JONES_POWER_BALL_DOMAIN = "jackpot.dowjonespowerball.com";
export const JACKPOT_INDIA_VIP_DOMAIN = "";
export const JACKPOT_INDIA_VIP_PLUS_DOMAIN = "";

export const VN_INDEX_VIP_DEFAULT = {
    lang: "vi",
    color: "#0057C1",
    bgReward: "#e1f5fe",
    first_id: "66b0d96ffafaddf7860148c2",
    second_id: "66b0d85e47dd479f3801daa2",
    third_id: "66ab41d4bb8b26d4c0023312",
    disable_time_list: [
        {
            start: "10:10",
            end: "11:10",
        },
        {
            start: "14:40",
            end: "15:40",
        },
    ],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "11.10",
    start_third_time: "14.55",
};

export const LOTTO_SUPPER_RICH_DEFAULT = {
    lang: "en",
    color: "#F10003",
    bgReward: "#ffe6e6",
    first_id: "66ab3f322a3c0896430a71f2",
    second_id: "66ab3ff12a3c0896430a71f3",
    third_id: "66ab4144c1a3b41d8b0178f2",
    disable_time_list: [
        {
            start: "10:10",
            end: "11:10",
        },
        {
            start: "14:40",
            end: "15:40",
        },
    ],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "11.10",
    start_third_time: "14.55",
};

export const SGX_VIP_DEFAULT = {
    lang: "en",
    color: "#1c204d",
    bgReward: "#ecedf8",
    first_id: "66ab4188d14a3c8d9f012de2",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "08:00",
    end_time: "17:35",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const LSX_VIP_DEFAULT = {
    lang: "lo",
    color: "#a00024",
    bgReward: "#ffe6eb",
    first_id: "66ab42080d27187afe091ec2",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "09:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const HENG_SENG_VIP_DEFAULT = {
    lang: "en",
    color: "#246c28",
    bgReward: "#ecf9ed",
    first_id: "66b0d91547dd479f3801daa3",
    second_id: "66b0d7c8a0bac361070b2c82",
    third_id: "",
    disable_time_list: [
        {
            start: "11:10",
            end: "12:10",
        },
    ],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "11.10",
    start_third_time: "",
};

export const SHEN_ZHEN_INDEX_DEFAULT = {
    lang: "en",
    color: "#172d63",
    bgReward: "#eaeffa",
    first_id: "66b0d93828e9ba17da07f803",
    second_id: "66b0d7fc5581874f9809e7a2",
    third_id: "",
    disable_time_list: [
        {
            start: "10:35",
            end: "11:35",
        },
    ],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const NIKKEI_VIP_STOCK_DEFAULT = {
    lang: "en",
    color: "#005790",
    bgReward: "#e6f5ff",
    first_id: "66b0d99318cf36ef270552b2",
    second_id: "66b0d88328e9ba17da07f802",
    third_id: "",
    disable_time_list: [
        {
            start: "09:35",
            end: "10:35",
        },
    ],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "11.10",
    start_third_time: "",
};

export const KTOP_VIP_INDEX_DEFAULT = {
    lang: "en",
    color: "#17a9ac",
    bgReward: "#e9fcfc",
    first_id: "66b0d8aea0bac361070b2c83",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const TSEC_VIP_INDEX_DEFAULT = {
    lang: "en",
    color: "#0057C1",
    bgReward: "#e1f5fe",
    first_id: "66b0d8ece699eae2b10d5af2",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const DOW_JONES_POWER_BALL_DEFAULT = {
    lang: "en",
    color: "#0057C1",
    bgReward: "#e1f5fe",
    first_id: "66b0d9bc409f18373e037d82",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "08:00",
    end_time: "17:15",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const INDIA_VIP_DEFAULT = {
    lang: "en",
    color: "#FF681A",
    bgReward: "#ffddcc",
    first_id: "66d5ddc31347264e270c81f2",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "10:00",
    end_time: "17:35",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};

export const INDIA_VIP_PLUS_DEFAULT = {
    lang: "en",
    color: "#016A34",
    bgReward: "#e6fff2",
    first_id: "66d5e29a4b340b81de02cf42",
    second_id: "",
    third_id: "",
    disable_time_list: [],
    start_time: "10:00",
    end_time: "17:35",
    start_first_time: "00:00",
    start_second_time: "",
    start_third_time: "",
};
