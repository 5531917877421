import {
    DOW_JONES_POWER_BALL_DOMAIN,
    HENG_SENG_VIDOMAIN,
    JACKPOT_DOW_JONES_POWER_BALL_DOMAIN,
    JACKPOT_HENG_SENG_VIDOMAIN,
    JACKPOT_KTOP_VIP_INDEX_DOMAIN,
    JACKPOT_LOTTO_SUPPER_RICh_DOMAIN,
    JACKPOT_LSX_VIP_DOMAIN,
    JACKPOT_NIKKEI_VIP_STOCKDOMAIN,
    JACKPOT_SGX_VIP_DOMAIN,
    JACKPOT_SHEN_ZHEN_INDEX_DOMAIN,
    JACKPOT_TSEC_VIP_INDEX_DOMAIN,
    JACKPOT_VN_INDEX_VIP_DOMAIN,
    KTOP_VIP_INDEX_DOMAIN,
    LOTTO_SUPPER_RICh_DOMAIN,
    LSX_VIP_DOMAIN,
    NIKKEI_VIP_STOCKDOMAIN,
    SGX_VIP_DOMAIN,
    SHEN_ZHEN_INDEX_DOMAIN,
    TSEC_VIP_INDEX_DOMAIN,
    VN_INDEX_VIP_DOMAIN,
} from "../constants/ConfigPlus";
import {
    DOW_JONES_EXTRA_DOMAIN,
    DOW_JONES_MIDNIGHT_DOMAIN,
    DOW_JONES_STAR_DOMAIN,
    DOW_JONES_TV_DOMAIN,
    HANOI_ASEAN_DOMAIN,
    HANOI_EXTRA_DOMAIN,
    JACKPOT_DOW_JONES_EXTRA_DOMAIN,
    JACKPOT_DOW_JONES_MIDNIGHT_DOMAIN,
    JACKPOT_DOW_JONES_STAR_DOMAIN,
    JACKPOT_DOW_JONES_TV_DOMAIN,
    JACKPOT_HANOI_ASEAN_DOMAIN,
    JACKPOT_HANOI_EXTRA_DOMAIN,
    JACKPOT_LAD_RED_CROSS_DOMAIN,
    JACKPOT_LAO_CITIZEN_DOMAIN,
    JACKPOT_LAO_EXTRA_DOMAIN,
    JACKPOT_LAO_HD_DOMAIN,
    JACKPOT_LAO_PA_TU_XAY_DOMAIN,
    JACKPOT_LAO_SAN_TI_PAP_DOMAIN,
    JACKPOT_LAO_SA_SEAN_DOMAIN,
    JACKPOT_LAO_STAR_DOMAIN,
    JACKPOT_LAO_STAR_VIP_DOMAIN,
    JACKPOT_LAO_TV_DOMAIN,
    JACKPOT_LAO_UNION_DOMAIN,
    JACKPOT_LAO_UNION_VIP_DOMAIN,
    JACKPOT_MINHNGOC_DOMAIN,
    JACKPOT_MINHNGOC_STAR_DOMAIN,
    JACKPOT_MINHNGOC_TV_DOMAIN,
    JACKPOT_XOSODEVELOP_DOMAIN,
    JACKPOT_XO_SO_DOMAIN,
    JACKPOT_XO_SO_RED_CROSS_DOMAIN,
    JACKPOT_XO_SO_UNION_DOMAIN,
    LAD_RED_CROSS_DOMAIN,
    LAO_CITIZEN_DOMAIN,
    LAO_EXTRA_DOMAIN,
    LAO_HD_DOMAIN,
    LAO_PA_TU_XAY_DOMAIN,
    LAO_SAN_TI_PAP_DOMAIN,
    LAO_SA_SEAN_DOMAIN,
    LAO_STAR_DOMAIN,
    LAO_STAR_VIP_DOMAIN,
    LAO_TV_DOMAIN,
    LAO_UNION_DOMAIN,
    LAO_UNION_VIP_DOMAIN,
    MINHNGOC_DOMAIN,
    MINHNGOC_STAR_DOMAIN,
    MINHNGOC_TV_DOMAIN,
    XOSODEVELOP_DOMAIN,
    XO_SO_DOMAIN,
    XO_SO_RED_CROSS_DOMAIN,
    XO_SO_UNION_DOMAIN,
} from "../constants/Configs";
import {
    DowJonesExtraPage,
    DowJonesMidnightPage,
    DowJonesPowerBallPage,
    DowJonesStarPage,
    DowJonesTvPage,
    HanoiAseanPage,
    HanoiExtraPage,
    HengSengVipPage,
    KtopVipIndexPage,
    LaoCitizenPage,
    LaoHdPage,
    LaoPaTuXayPage,
    LaoRedCrossPage,
    LaoSanTiPapPage,
    LaoSaSeanPage,
    LaosExtraPage,
    LaoStarPage,
    LaoStarVipPage,
    LaoTvPage,
    LaoUnionPage,
    LaoUnionVipPage,
    LottoSupperRichPage,
    LsxVipPage,
    MinhngocPage,
    MinhngocStarPage,
    MinhngocTvPage,
    NikkeiVipStockPage,
    SgxVipPage,
    ShenZhenIndexPage,
    TsecVipIndexPage,
    VnIndexVip2Page,
    XosodevelopPage,
    XoSoHdPage,
    XoSoRedCrossPage,
    XoSoUnionPage,
} from "../pages";

function Layout() {
    const host = window.location.host;
    switch (host) {
        case LAD_RED_CROSS_DOMAIN:
        case JACKPOT_LAD_RED_CROSS_DOMAIN:
            return <LaoRedCrossPage />;
        case LAO_SAN_TI_PAP_DOMAIN:
        case JACKPOT_LAO_SAN_TI_PAP_DOMAIN:
            return <LaoSanTiPapPage />;
        case DOW_JONES_EXTRA_DOMAIN:
        case JACKPOT_DOW_JONES_EXTRA_DOMAIN:
            return <DowJonesExtraPage />;
        case LAO_CITIZEN_DOMAIN:
        case JACKPOT_LAO_CITIZEN_DOMAIN:
            return <LaoCitizenPage />;
        case LAO_EXTRA_DOMAIN:
        case JACKPOT_LAO_EXTRA_DOMAIN:
            return <LaosExtraPage />;
        case DOW_JONES_MIDNIGHT_DOMAIN:
        case JACKPOT_DOW_JONES_MIDNIGHT_DOMAIN:
            return <DowJonesMidnightPage />;
        case DOW_JONES_STAR_DOMAIN:
        case JACKPOT_DOW_JONES_STAR_DOMAIN:
            return <DowJonesStarPage />;
        case DOW_JONES_TV_DOMAIN:
        case JACKPOT_DOW_JONES_TV_DOMAIN:
            return <DowJonesTvPage />;
        case HANOI_EXTRA_DOMAIN:
        case JACKPOT_HANOI_EXTRA_DOMAIN:
            return <HanoiExtraPage />;
        case LAO_PA_TU_XAY_DOMAIN:
        case JACKPOT_LAO_PA_TU_XAY_DOMAIN:
            return <LaoPaTuXayPage />;
        case LAO_SA_SEAN_DOMAIN:
        case JACKPOT_LAO_SA_SEAN_DOMAIN:
            return <LaoSaSeanPage />;
        case LAO_STAR_VIP_DOMAIN:
        case JACKPOT_LAO_STAR_VIP_DOMAIN:
            return <LaoStarVipPage />;
        case LAO_UNION_VIP_DOMAIN:
        case JACKPOT_LAO_UNION_VIP_DOMAIN:
            return <LaoUnionVipPage />;
        case LAO_UNION_DOMAIN:
        case JACKPOT_LAO_UNION_DOMAIN:
            return <LaoUnionPage />;
        case XOSODEVELOP_DOMAIN:
        case JACKPOT_XOSODEVELOP_DOMAIN:
            return <XosodevelopPage />;
        case HANOI_ASEAN_DOMAIN:
        case JACKPOT_HANOI_ASEAN_DOMAIN:
            return <HanoiAseanPage />;
        case LAO_TV_DOMAIN:
        case JACKPOT_LAO_TV_DOMAIN:
            return <LaoTvPage />;
        case XO_SO_DOMAIN:
        case JACKPOT_XO_SO_DOMAIN:
            return <XoSoHdPage />;
        case LAO_HD_DOMAIN:
        case JACKPOT_LAO_HD_DOMAIN:
            return <LaoHdPage />;
        case MINHNGOC_TV_DOMAIN:
        case JACKPOT_MINHNGOC_TV_DOMAIN:
            return <MinhngocTvPage />;
        case LAO_STAR_DOMAIN:
        case JACKPOT_LAO_STAR_DOMAIN:
            return <LaoStarPage />;
        case XO_SO_RED_CROSS_DOMAIN:
        case JACKPOT_XO_SO_RED_CROSS_DOMAIN:
            return <XoSoRedCrossPage />;
        case XO_SO_UNION_DOMAIN:
        case JACKPOT_XO_SO_UNION_DOMAIN:
            return <XoSoUnionPage />;
        case MINHNGOC_STAR_DOMAIN:
        case JACKPOT_MINHNGOC_STAR_DOMAIN:
            return <MinhngocStarPage />;
        case MINHNGOC_DOMAIN:
        case JACKPOT_MINHNGOC_DOMAIN:
            return <MinhngocPage />;

        case VN_INDEX_VIP_DOMAIN:
        case JACKPOT_VN_INDEX_VIP_DOMAIN:
            return <VnIndexVip2Page />;
        case LOTTO_SUPPER_RICh_DOMAIN:
        case JACKPOT_LOTTO_SUPPER_RICh_DOMAIN:
            return <LottoSupperRichPage />;
        case LSX_VIP_DOMAIN:
        case JACKPOT_LSX_VIP_DOMAIN:
            return <LsxVipPage />;
        case SGX_VIP_DOMAIN:
        case JACKPOT_SGX_VIP_DOMAIN:
            return <SgxVipPage />;
        case HENG_SENG_VIDOMAIN:
        case JACKPOT_HENG_SENG_VIDOMAIN:
            return <HengSengVipPage />;
        case SHEN_ZHEN_INDEX_DOMAIN:
        case JACKPOT_SHEN_ZHEN_INDEX_DOMAIN:
            return <ShenZhenIndexPage />;
        case NIKKEI_VIP_STOCKDOMAIN:
        case JACKPOT_NIKKEI_VIP_STOCKDOMAIN:
            return <NikkeiVipStockPage />;
        case KTOP_VIP_INDEX_DOMAIN:
        case JACKPOT_KTOP_VIP_INDEX_DOMAIN:
            return <KtopVipIndexPage />;
        case TSEC_VIP_INDEX_DOMAIN:
        case JACKPOT_TSEC_VIP_INDEX_DOMAIN:
            return <TsecVipIndexPage />;
        case DOW_JONES_POWER_BALL_DOMAIN:
        case JACKPOT_DOW_JONES_POWER_BALL_DOMAIN:
            return <DowJonesPowerBallPage />;
        default:
            return window.location.replace("/home");
    }
}

export default Layout;
